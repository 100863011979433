import { Routes, Route, Navigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import AuthGuard from './utils/guards/AuthGuard';
import FeedBack from './pages/feedback/FeedBack';
import Tag from './pages/tag/Tag';
// import FeedbackLayout from "./pages/feedback/FeedbackLayout";
import LibraryFeedBack from './pages/feedback/LibraryFeedBack';
import ReportAsSpam from './pages/spam/ReportAsSpam';

const Layout = React.lazy(() => import('./pages/Layout'));
const ReleaseNote = React.lazy(() => import('./pages/releaseNote/ReleaseNote'));
const ReleaseDetails = React.lazy(() =>
  import('./pages/releaseNote/ReleaseDetails')
);
// const ReleaseNoteAdd = React.lazy(() =>
//   import("./pages/releaseNote/ReleaseNoteAdd")
// );
const ReleaseNotePreview = React.lazy(() =>
  import('./pages/releaseNote/ReleaseNotePreview')
);
// const ReleaseNotePreviewLink = React.lazy(() =>
//   import("./pages/releaseNote/ReleaseNotePreviewLink")
// );
// const Feedback = React.lazy(() => import("./pages/feedback/Feedback"));
const NotFound = React.lazy(() => import('./pages/notFound/NotFound'));
// const Tag = React.lazy(() => import("./pages/tag/Tag"));
const Dl = React.lazy(() => import('./pages/dl/Dl'));
const DlMember = React.lazy(() => import('./pages/dlMember/DlMember'));
const FeedbackLayout = React.lazy(() =>
  import('./pages/feedback/FeedbackLayout')
);
// const Library = React.lazy(() => import("./pages/feedback/Library"));
const PublicLIbrary = React.lazy(() =>
  import('./pages/feedback/PublicLIbrary')
);
const Unsubscribe = React.lazy(() => import('./pages/subscribe/Unsubscribe'));
// const FeedbackGridView = React.lazy(() =>
//   import("./components/feedback/FeedbackGridView")
// );
const IdentityVerification = React.lazy(() =>
  import('./pages/subscribe/IdentityVerification')
);
const Login = React.lazy(() => import('./pages/authentication/Login'));
const WixLogin = React.lazy(() => import('./pages/authentication/wixLogin'));
const RegisterCustomer = React.lazy(() =>
  import('./pages/authentication/RegisterCustomer')
);
const JiraConnectorRelease = React.lazy(() =>
  import('./pages/authentication/jiraConnectorRelease')
);
const UserInvite = React.lazy(() => import('./pages/public/UserInvite'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));
const GlobalFeedBackLayout = React.lazy(() =>
  import('./pages/globalFeedback/FeedBackLayout')
);
const TagList = React.lazy(() => import('./pages/tag/Tag'));
const Settings = React.lazy(() => import('./pages/settings/Settings'));
const UserSettings = React.lazy(() => import('./pages/settings/UserSettings'));
const Subscribers = React.lazy(() =>
  import('./pages/subscribers/SubscribersLayout')
);
const NoLevel2 = React.lazy(() => import('./pages/level2Warning/NoLevel2'));
const GlobalNoLevel2 = React.lazy(() =>
  import('./pages/globalLevel2Warning/NoLevel2')
);
const PreviewNoLevel = React.lazy(() =>
  import('./pages/previewLevel2Warning/NoLevel2')
);
const Verify = React.lazy(() => import('./pages/subscribe/Verify'));
const Spam = React.lazy(() => import('./pages/spam/ReportAsSpam'));
const SpamSuccess = React.lazy(() => import('./pages/spam/ReportSpamSuccess'));

function Router() {
  return (
    <React.Fragment>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path='/' element={<Navigate replace to='/release-note' />} />
          {/* Protected Routes start */}
          <Route element={<AuthGuard />}>
            <Route path='/release-note/' element={<Layout />}>
              <Route path={`dashboard`} element={<Dashboard />} />
              <Route
                path={`edit/:releaseId/:level2Id`}
                element={<ReleaseNote />}
              />
              <Route path={`dl/:level2Id`} element={<Dl />} />
              <Route path={`dl-member/:dId`} element={<DlMember />} />

              <Route
                path={`details/:releaseId/:level2Id`}
                element={<ReleaseDetails />}
              />
              <Route path={'feedbacks'} element={<GlobalFeedBackLayout />} />
              <Route path={`dl/:level2Id`} element={<Dl />} />
              <Route path={'tags'} element={<TagList />} />
              <Route path={'settings/:id'} element={<Settings />} />
              <Route path={`subscribers`} element={<Subscribers />} />
              <Route path={`no-level-exist/:level`} element={<NoLevel2 />} />
            </Route>
          </Route>
          {/* Protected Routes end */}
          <Route path={`login`} element={<Login />} />
          <Route path={`login-wix`} element={<WixLogin />} />
          <Route path={`register-customer`} element={<RegisterCustomer />} />
          <Route
            path={`connector-release/:jiraInstanceName/:projectID/:releaseID/:l1/:l2`}
            element={<JiraConnectorRelease />}
          />
          <Route path='/feedback/' element={<FeedbackLayout />}>
            <Route path='customer-feedback' element={<LibraryFeedBack />} />
          </Route>
          <Route path='/lib' element={<FeedbackLayout />}>
            <Route path=':dnsName?/:key?' element={<PublicLIbrary />} />
          </Route>
          <Route path='/global/deleted-level' element={<GlobalNoLevel2 />} />
          <Route path='/preview/deleted-level' element={<PreviewNoLevel />} />
          <Route path='/report-spam' element={<Spam />} />
          <Route
            path={`preview/:releaseNoteId/:level2Id`}
            element={<ReleaseNotePreview />}
          />
          <Route path='/customer-verfication' element={<Verify />} />
          <Route
            path='feedback/customer-unsubscribe'
            element={<IdentityVerification />}
          />
          <Route
            path='/customer-unsubscribe/success'
            element={<Unsubscribe />}
          />
          <Route path='report-spam/success' element={<SpamSuccess />} />
          <Route path={`user/invite`} element={<UserInvite />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
}

export default Router;
