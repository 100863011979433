export const STORE_CONST = {
  COMMON_NAMESPACE: 'common',
  SIDEBAR_NAMESPACE: 'sidebar',
  RELEASENOTE_NAMESPACE: 'releaseNote',
  TAG_NAMESPACE: 'tag',
  DL_NAMESPACE: 'dl',
  FEEDBACK_NAMESPACE: 'feedback',
  AUTORIZATION_NAMESPACE: 'authorization',
  RELEASE_STATUS_ALL: 'all',
  RELEASE_STATUS_OTHERS: 'others',
  PREFERENCE_NAME_SPACE: 'preference',
  SETTINGS_NAME_SPACE: 'settings',
  FILE_READING: 'fileReading',
  GLOBAL_SUBSCRIBERS: 'subscribers',
  DNS_SETTINGS_NAME_SPACE: 'dnsSettings',
  FILE_READING: 'fileReading',
  JIRA_CONNECTOR: 'jiraConnectorRelease',
};
