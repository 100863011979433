export const PROJECT_CONST = {
  AMOUNT_FORMAT: /\B(?=(\d{3})+(?!\d))/g,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_MATCH_PATTERN: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  // URL_MATCH_PATTERN:
  //   /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b)([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
  URL_MATCH_PATTERN: /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-z]{2,6}$/i,
  RESTRICT_SPECIAL_CHAR_PATTERN: /[^A-Z0-9 ]/gi,
  LEADING_SPACE_PATTERN: /^\s+/,
  TRAILING_SPACE_PATTERN: /\s+$/,
  DEFAULT_VERSION: '1.0.0',
  API_ERROR: 'Error',
  API_SUCCESS: 'Success',
  ADD_RELEASE_NOTE: 1,
  EDIT_RELEASE_NOTE: 2,
  DRAFT_STATUS: 1,
  PUBLISH_STATUS: 2,
  DRAFT_TEXT: 'Draft',
  PUBLISH_TEXT: 'Published',
  SCHEDULED_TEXT: 'Scheduled',
  SENDING_TEXT: 'Sending',
  EDITED_TEXT: 'Edited',
  INITIAL_LOAD: 1,
  TAG_CREATED_MSG: 'Tags Created Successfully',
  TAG_UPDATED_MSG: 'Tag Updated Successfully',
  TAGS_DELETED_MSG: 'Tags Deleted Successfully',
  TAG_DELETED_MSG: 'Tag Deleted Successfully',
  TAG_APPLIED_MSG: 'Tags Applied Successfully',
  TAG_ADDED_MSG: 'Tags Added successfully',
  TAG_REMOVED_MSG: 'Tag Removed Successfully',
  DISTRIBUTION_CREATED_MSG: 'Distribution List Created Successfully',
  DISTRIBUTION_UPDATED_MSG: 'Distribution Updated Successfully',
  DISTRIBUTIONS_DELETED_MSG: 'Distributions Deleted Successfully',
  DISTRIBUTION_DELETED_MSG: 'Distribution Deleted Successfully',
  DISTRIBUTION_MEMBER_SINGLE_CREATED_MSG:
    'Distribution Target Created and Verification Email Sent Successfully',
  DISTRIBUTION_MEMBER_MULTI_CREATED_MSG:
    'Distribution Targets Created and Verification Email Sent Successfully',

  DISTRIBUTION_MEMBER_UPDATED_MSG: 'Distribution Target Updated Successfully',
  DISTRIBUTION_MEMBER_DELETED_MSG_MULTIPLE:
    'Distribution Targets Deleted Successfully',
  DISTRIBUTION_MEMBER_DELETED_MSG: 'Distribution Target Deleted Successfully',
  DISTRIBUTION_EMAIL_SEND_MSG: 'Email Resend Successfully',
  RELEASE_NOTE_CREATED_MSG: 'Release Created Successfully',
  RELEASE_NOTE_UPDATED_MSG: 'Release Updated Successfully',
  RELEASE_NOTE_DELETED_MSG: 'Release Deleted Successfully',
  RELEASE_NOTE_PUBLISHED_MSG: 'Release note published successfully',
  PUBLIC_PREFERENCES_UPDATE_MSG: 'Public Preferences Updated Successfully',
  PUBLISH_MSG: 'Published Successfully',
  FEEDBACK_SUBMITTED_MSG: 'Feedback Submitted Successfully',
  FEEDBACK_REVIEWED_MSG: 'Feedback reviewed successfully',
  JIRA_CRED_CREATED_MSG: 'Jira Credentials Created Successfully',
  JIRA_CRED_UPDATED_MSG: 'Jira Credentials Updated Successfully',
  FACEBOOK_UPDATED_MSG: 'Facebook Details Updated Successfully',
  TWITTER_UPDATED_MSG: 'Twitter Details Updated Successfully',
  LINKEDIN_UPDATED_MSG: 'LinkedIn Details Updated Successfully',
  IMG_BASE_PATH: process.env.REACT_APP_API_URL + 'media/uploads',
  DEFAULT_COLOR: '#43438cff',
  CKEDITOR_MIN_HEIGHT: '350',
  DRAFT_STATUS_FILTER: 2,
  PUBLISHED_STATUS_FILTER: 3,
  ALL_STATUS_FILTER: 1,
  LIST_VIEW: 1,
  GRID_VIEW: 2,
  LIST_VIEW_TEXT: 'List',
  GRID_VIEW_TEXT: 'Grid',
  DEFAULT_FEEDBACK_TEXT_LENGTH: 69,
  FILTER_FEEDBACK_ALL: 0,
  FILTER_FEEDBACK_REVIEWED: 1,
  FILTER_FEEDBACK_NOT_REVIEWED: 2,
  FROM_PUBLISH: 'FROM_PUBLISH',
  NAME_FILTER: 1,
  CREATED_DATE_FILTER: 2,
  PUBLISHED_DATE_FILTER: 3,
  CLEAR_FILTER: 2,
  APPLY_FILTER: 1,
  DROPDOWN_ARR: [
    {
      key: 1,
      value: 'Release Version',
      sortOrder: 3,
    },
    {
      key: 2,
      value: 'Created Date',
      sortOrder: 1,
    },
    {
      key: 3,
      value: 'Published Date',
      sortOrder: 2,
    },
  ],
  ZERO_CONST: 0,
  EXCLUDE_URL_ARR: ['authenticate'],
  BAR_CHART_DATA: {
    labels: [],
    datasets: [
      {
        label: 'Release',
        data: [],
        backgroundColor: [], // Bar color
        // borderColor: ["rgba(75, 192, 192, 1)", "rgb(255, 99, 132,1)"], // Border color
        borderWidth: 1, // Border width
        barThickness: 20,
        borderRadius: 10,
      },
    ],
  },
  BAR_CHART_OPTIONS: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  },
  MAX_FILE_SIZE_FOR_FEEDBACK_ICONS: 150,
  MAX_FILE_SIZE: 2048,
  MAX_IMG_WIDTH: 1200,
  MAX_IMG_HEIGHT: 600,
  EXTENSION_ARR: ['png', 'PNG', 'JPEG', 'jpeg', 'JPG', 'jpg'],
  FILE_EXTENSION: ['CSV', 'csv', 'txt', 'TXT', 'XLSX', 'xlsx'],
  CSV_EXTENSION: ['CSV', 'csv'],
  XLSX_EXTENSION: ['XLSX', 'xlsx'],
  FILTER_TYPE_ALL: 1,
  FILTER_TYPE_INITIAL: 1,
  FILTER_TYPE_APPLIED: 2,
  FILTER_TYPE_CLEAR: 3,
  RELEASE_NAME_MAX_LIMIT: 30,
  ADMIN_SETTINGS_MIN_PASS_LIMIT: 5,
  ADMIN_SETTINGS_MIN_PASS_LIMIT_MSG:
    'Password Must Contain at least 5 Characters',
  MULTISTEP_RELEASECHOICE_ID: 3,
  JIRA_VIEW_TYPE_LIST: 1,
  JIRA_VIEW_TYPE_PARA: 2,
  JIRA_CRED_TYPE_ADD: 1,
  JIRA_CRED_TYPE_EDIT: 2,
  REGEX_STRING_PATTERN: /\((.*?)\)/g,
  REGEX_HTML_PATTERN: /href="([^"]*)"/g,
  DEFAULT_INPUT_COLOR: '#f3f3f3',
  ERROR_INPUT_COLOR: '#f83f4f',
  REGX_INPUT_JIRAKEY_VALIDATION: /([a-zA-Z])([0-9]?)-([0-9])/g,
  COPY_TO_CLIPBOARD: 1,
  COPY_TO_RELEASE_NOTE: 2,
  PAGINATION_DEFAULT_LIMIT: 15,
  PAGINATION_DEFAULT_LIMIT_FOR_GLOBAL_FEEDBACK: 20,
  PAGINATION_DEFAULT_PAGE: 1,
  WHEN_TO_RELEASE_TAB: 1,
  WHAT_CONTENT_TO_RELEASE_TAB: 2,
  WHO_SHOULD_RECEIVE: 3,
  PUBLISH_NOW: 1,
  SCHEDULE: 2,
  TWO_STEP: 3,
  PUBLISH: 'publish',
  SAVE: 'save',
  OTP_SEND: 'One Time Passcode Send Successfully',
  OTP_RESEND: 'One Time Passcode Resend Successfully',
  VERIFICATION_SUCCESS: 'Verification Successful',
  SCHEDULE_TIMEZONE: 1,
  TWOSTEP_TIMEZONE_ONE: 2,
  TWOSTEP_TIMEZONE_TWO: 3,
  FEED_BACK_DELETE: 'Feedback Deleted Successfully',
  FEED_BACKS_DELETE: 'Feedbacks Deleted Successfully',
  REPLY_MSG: 'Replied Successfully',
  FORWARD_MSG: 'Forwarded Successfully',
  MARK_AS_READ: 'Marked as Read',
  MARK_AS_UNREAD: 'Marked as Unread',
  FACEBOOK: 'fbSettings',
  TWITTER: 'twitterSettings',
  LINKED_IN: 'linkedInSettings',
  RESET_PASSWORD_MSG: 'Do you want to reset your password?',
  RESET_PASSWORD_SUCCESS_MSG:
    "We've Just Sent You an Email to Reset Your Password. ",
  UPDATE_PROFILE_SUCCESS_MSG: 'User Profile Updated Successfully',
  UPDATE_JIRA_MARK_SHARED_SUCCESS_MSG: 'Shared Updated Successfully',
  UPDATE_JIRA_STATUS_CHANGE_SUCCESS_MSG: 'Status Updated Successfully',
  UPDATE_JIRA_CRED_DELETE_SUCCESS_MSG: 'Jira Credentials Deleted Successfully',
  SUBSCRIBER_PREFERENCE_UPDATE: 'Subscription Preferences Updated Successfully',
  UN_SUBSCRIBE_MSG: 'Unsubscribed Successfully',
  UPLOAD_SETTINGS_IMAGE_SUCCESS_MSG: 'Image Uploaded Successfully',
  REMOVE_SETTINGS_IMAGE_SUCCESS_MSG: 'Image Deleted Successfully',
  GLOBAL_CHECK_BOX: 1,
  ALLOW_ANYONE_TO_LEAVE_FEEDBACK: 2,
  MARK_AS_READ_AFTER_REPLYING: 3,
  ALLOW_ANYONE_TO_SUBSCRIBE: 4,
  SETTINGS_TYPE_USER: 1,
  SETTINGS_TYPE_APP: 1,
  JIRA_CRED_SHARED: 1,
  JIRA_CRED_ACTIVE: 2,
  LEVEL1_UPDATED_MSG: 'Level 1 Name Updated Successfully',
  LEVEL2_UPDATED_MSG: 'Level 2 Name Updated Successfully',
  EMAIL_EXIST_MSG: 'Some emails are already exist',
  DISTRIBUTION_BULK_UPDATE: 'Distribution Targets Created Successfully',
  COPY_PASTE: 'Copy and Paste',
  FILE_IMPORT: 'File Import',
  DNS_SETTINGS_MSG: 'DNS Settings Updated Successfully',
  SECURITY_TYPE_PUBLIC: 1,
  SECURITY_TYPE_PASSWORD: 2,
  SECURITY_TYPE_JWT: 3,
  SECURITY_TYPE_NONE: 4,
  SECURITY_TYPE_STATUS_PAGE_NOT_FOUND: 404,
  SECURITY_TYPE_STATUS_SUCCESS: 200,
  UNAUTHORIZED_RELEASE_LIST_URL: 'feedback/release/list',
  UNAUTHORIZED_FEEDBACK_URL: 'release/getreleasewithfeedbackkey',
  UNAUTHORIZED_FEEDBACK_URL_WITH_RELEASE_ID: 'release/getreleasewithid',
  SINGLE_STEP_CANCEL_MSG: 'Scheduled Release Cancelled Successfully',
  MULTI_STEP_CANCEL_MSG: 'Scheduled Releases Cancelled Successfully',
  OTP_TYPE_SUBSCRIBE: 1,
  OTP_TYPE_UNSUBSCRIBE: 2,
  OTP_TYPE_SUBMIT_FEEDBACK: 3,
  OTP_TYPE_DELETE_FEEDBACK: 4,
  RELEASE_DELETE_MSG: 'Release Deleted Successfully',
  USER_ROLE: { 3: 'Administrator', 2: 'User' },
  USER_CREATE_SUCCESS_MSG: 'User Created Successfully',
  USER_INVITE_SUCCESS_MSG: 'User Invitation Send Successfully',
  USER_UPDATE_SUCCESS_MSG: 'User Updated Successfully',
  JIRA_TICKET_CREATION: 'Jira Ticket Created Successfully',
  GLOBAL_SUBSCRIBER_STATUS_ACTIVE_MSG: 'Subscriber Status Active Successfully',
  GLOBAL_SUBSCRIBER_STATUS_INACTIVE_MSG:
    'Subscriber Status Inactive Successfully',
  LEVEL_2READ_ONLY_MSG: 'This Level 2 is Read-Only',
  LEVEL_2_DELETE_MSG: 'This Level2 Deleted Successfully',
  LEVEL_1_DELETE_MSG: 'This Level1 Deleted Successfully',
  SUB_TASK_NAME: 'Sub-task',
  MAXLENGTH: 255,
  UNAUTHORIZED_FEEDBACK_SEND_OTP: 'feedback/add/send-otp',
  UNAUTHORIZED_FEEDBACK_RESEND_OTP: 'feedback/add/resend-otp',
  UNAUTHORIZED_SUBSCRIBER_SEND_OTP: 'feedback/subscriber/send-otp',
  UNAUTHORIZED_FEED_BACK_EDIT: 'feedback/edit',
  UNAUTHORIZED_FEED_BACK_ADD: 'feedback/add',
  UNAUTHORIZED_UNSUBSCRIBE: 'subscriber/unsubscribe',
  UNAUTHORIZED_SUBSCRIBE: 'subscriber/subscribe',
  UNAUTHORIZED_FEEDBACK_ADD_VERIFICATION: 'feedback/add/verification',
  UNAUTHORIZED_FEEDBACK_SUBSCRIBER_VERIFICATION:
    'feedback/subscriber/verification',
  UNAUTHORIZED_SECURITY_CHECK: 'securitytype',
  UNAUTHORIZED_FEEDBACK_DELETE: 'feedback/delete',
  UNAUTHORIZED_SUBSCRIBER_DETAILS: 'subscriber/details',
  UNAUTHORIZED_FEEDBACK_TAG_LIST: 'taglist',
  UNAUTHORIZED_FEEDBACK_RELEASE_LIST: 'feedback/release/list',
  EMAIL_VERIFIED: 'target/verified',
  PREVIEW: 'release/preview',
  EXCLUDE_DELETE_ARCHIVE_ARR: [
    'feedback/forward-feedback',
    'feedback/reply-feedback',
    'settings/user/level2',
    'jira/credentials/list',
    '/release-note/no-level-exist/Level1',
    'release/getreleasewithfeedbackkey',
    'feedback/reviewed',
    'release/getreleasewithid',
    'feedback/add/send-otp',
    'feedback/add/resend-otp',
    'feedback/edit',
    'feedback/add',
    'subscriber/unsubscribe',
    'subscriber/subscribe',
    'feedback/add/verification',
    'feedback/delete',
    'subscriber/details',
    'taglist',
    'target/verified',
    'jira/ticket/create',
    'release/preview',
    'feedback/id/delete',
    'securitytype',
    'feedback/release/list',
    'feedback/subscriber/send-otp',
    'feedback/subscriber/verification',
  ],
  FROM_GLOBALFEEDBACK: 'fromGlobalFeedBack',
  INCLUDE_DELETE: [
    'feedback/id/delete',
    'feedback/forward-feedback',
    'feedback/reply-feedback',
    'feedback/reviewed',
    'jira/ticket/create',
  ],
  REMOVE_ROUTE_ARR: [
    '/release-note/no-level-exist/Level2',
    '/release-note/no-level-exist/Level1',
  ],
  CHOICE_IDS: [1, 2, 3],
  SUBSCRIBER_DELETED_SUCCESS: 'Subscriber Deleted Successfully',
  SUBSCRIBERS_DELETED_SUCCESS: 'Subscribers Deleted Successfully',
  LEVEL1_ARCHIVE: 'This Level1 Archived Successfully',
  LEVEL2_ARCHIVE: 'This Level2 Archived Successfully',
  LEVEL2_DEARCHIVE: 'This Level2 De-Archived Successfully',
  LEVEL1_DEARCHIVE: 'This Level1 De-Archived Successfully',
  USER_DEACTIVATE_MSG: 'Account Inactivated Successfully',
  ROW_DELETE: 'rowDelete',
  API_CALL: 'apiCall',
  USER_RESEND_MSG: 'User Invitation Sent',
  LEVEL1: 'level1',
  LEVEL2: 'level2',
  BUSINESS_HIERARCHY_MSG: 'Business Hierarchy Details Updated Successfully',
  RELEASE_DESCRIPTION_NAME_MAX_LIMIT: 60,
  USER_RESET_PASSWORD_SUCCESS_MSG:
    'If the email address you entered is associated with an active account, you will receive an email to reset your password shortly.',
  WARNING_MSG_FOR_SUBSCRIBE_TO_UPDATE:
    "You don't have permission to subscribe on this ",
  WARNING_MSG_FOR_ALLOW_TO_LEAVE_FEEDBACK:
    "You don't have permission to Provide Feedback on this ",
  MAX_HEIGHT_FOR_COMPANY_PROFILE_IMG: 35,
  UPDATE_COMPANY_PROFILE_SUCCESS_MSG: 'Company Profile Updated Successfully',
  EXTERNAL_PAGE: 'externalPage',
  PAGE_AUTHORISATION: 'pageauthorisation',
  APPLICATION_TOKEN_DELETE: 'Application Token Deleted Successfully',
  APPLICATION_TOKEN_CREATE: 'Application Token Created Successfully',
  USER_PERMISSION_TYPE: {
    SETTINGS_VIEW_COMPANY_PROFILE: 'SETTINGS_VIEW_COMPANY_PROFILE',
    SETTINGS_VIEW_USER_PROFILE: 'SETTINGS_VIEW_USER_PROFILE',
    SETTINGS_VIEW_JIRA_INTEGRATION: 'SETTINGS_VIEW_JIRA_INTEGRATION',
    SETTINGS_VIEW_FEEDBACK: 'SETTINGS_VIEW_FEEDBACK',
    SETTINGS_VIEW_BUSINESS_HIERARCHY: 'SETTINGS_VIEW_BUSINESS_HIERARCHY',
    SETTINGS_VIEW_MANAGE_USERS: 'SETTINGS_VIEW_MANAGE_USERS',
    SETTINGS_VIEW_PUBLIC_PAGE: 'SETTINGS_VIEW_PUBLIC_PAGE',
    SETTINGS_VIEW_APPLICATION_TOKENS: 'SETTINGS_VIEW_APPLICATION_TOKENS',
    CREATE_JIRA_FEEDBACK: 'CREATE_JIRA_FEEDBACK',
    UPDATE_JIRA_CREDENTIAL: 'UPDATE_JIRA_CREDENTIAL',
    DELETE_SUBSCRIBER: 'DELETE_SUBSCRIBER',
    CREATE_RELEASE_NOTE: 'CREATE_RELEASE_NOTE',
    EDIT_RELEASE_NOTE: 'EDIT_RELEASE_NOTE',
    DELETE_RELEASE_NOTE: 'DELETE_RELEASE_NOTE',
    CANCEL_RELEASE_SCHEDULE: 'CANCEL_RELEASE_SCHEDULE',
    VIEW_TAGS_PANEL: 'VIEW_TAGS_PANEL',
    CREATE_NEW_TAG: 'CREATE_NEW_TAG',
    DELETE_TAG: 'DELETE_TAG',
    EDIT_TAG: 'EDIT_TAG',
    VIEW_DISTRIBUTION_LIST_PANEL: 'VIEW_DISTRIBUTION_LIST_PANEL',
    CREATE_DISTRIBUTION_LIST: 'CREATE_DISTRIBUTION_LIST',
    DELETE_DISTRIBUTION_LIST: 'DELETE_DISTRIBUTION_LIST',
    EDIT_DISTRIBUTION_LIST: 'EDIT_DISTRIBUTION_LIST',
    VIEW_FEEDBACK_PANEL: 'VIEW_FEEDBACK_PANEL',
    REPLY_FEEDBACK: 'REPLY_FEEDBACK',
    FORWARD_FEEDBACK: 'FORWARD_FEEDBACK',
    CHANGE_FEEDBACK_STATUS: 'CHANGE_FEEDBACK_STATUS',
    DELETE_FEEDBACK: 'DELETE_FEEDBACK',
    VIEW_SUBSCRIBER_PANEL: 'VIEW_SUBSCRIBER_PANEL',
    CREATE_LEVEL1: 'CREATE_LEVEL1',
    CREATE_LEVEL2: 'CREATE_LEVEL2',
    RENAME_LEVEL1: 'RENAME_LEVEL1',
    RENAME_LEVEL2: 'RENAME_LEVEL2',
    DELETE_LEVEL1: 'DELETE_LEVEL1',
    DELETE_LEVEL2: 'DELETE_LEVEL2',
    ARCHIVE_LEVEL1: 'ARCHIVE_LEVEL1',
    ARCHIVE_LEVEL2: 'ARCHIVE_LEVEL2',
    CHANGE_SUBSCRIBER_STATUS: 'CHANGE_SUBSCRIBER_STATUS',
  },
  STEP: [
    {
      selector: '[data-tour="step-1"]',
      content: (
        <>
          We’ve created your first Level 1 and Level 2. For more about the
          business hierarchy, please see the help guides{' '}
          <a
            href="https://srn-app.com/help"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>.
        </>
      ),
    },
    {
      selector: '[data-tour="step-2"]',
      content:
        'Click here to create your first release note, otherwise, we’ve created a sample for you.',
    },
    {
      selector: '[data-tour="step-3"]',
      content: 'Profile and application settings are located here.',
    },
    {
      selector: '[data-tour="step-4"]',
      content:
        'You can update your company settings here. Try it now by adding the name, website, support email and logo for your company',
    },
  ],
  AI_GENERATE_OPTIONS : [
    { key: "releaseNote", value: "Release Note" },
    { key: "executiveSummary", value: "Executive Summary" },
    { key: "serviceDesk", value: "Service Desk" },
    { key: "kbArticle", value: "KB Article" },
    { key: "marketing", value: "Marketing" }
  ],
};
