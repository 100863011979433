import { createSlice } from '@reduxjs/toolkit';
import { STORE_CONST } from '../utils/constants/store';

const JiraConnectorSlice = createSlice({
  name: STORE_CONST.JIRA_CONNECTOR,
  initialState: {
    jiraInstanceName: null,
    projectID: null,
    releaseID: null,
    l1: null,
    l2: null,
    srnToken: null,
  },
  reducers: {
    setParamsJiraConnector: (state, action) => {
      state.jiraInstanceName = action.payload.jiraInstanceName;
      state.projectID = action.payload.projectID;
      state.releaseID = action.payload.releaseID;
      state.l1 = action.payload.l1;
      state.l2 = action.payload.l2;
      state.srnToken = action.payload.srnToken;
    },
  },
});

export const jiraConnectorReleaseAction = JiraConnectorSlice.actions;
export default JiraConnectorSlice;
