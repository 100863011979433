import React, { useEffect } from 'react';
import TagList from '../../components/tags/TagList';
import { useNavigate, useParams } from 'react-router-dom';
import { STORE_CONST } from '../../utils/constants/store';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { useSelector } from 'react-redux';
import '../dashboard/Dashboard.css';
import '../../components/dashboard/Topbar.css';
import '../../components/dashboard/ReleaseTable.css';
import './Tag.css';
import {
  getloginInfoRole,
  userPermission,
} from '../../utils/helper/commonHelper';
import { PROJECT_CONST } from '../../utils/constants/projectConst';

function Tag() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const usersPermissions = getloginInfoRole();
  useEffect(() => {
    if (
      !userPermission(
        usersPermissions,
        PROJECT_CONST.USER_PERMISSION_TYPE.VIEW_DISTRIBUTION_LIST_PANEL
      )
    ) {
      navigate('/release-note/dashboard');
    }
  }, [usersPermissions]);
  return (
    <>
      <TagList productId={productId}></TagList>
    </>
  );
}

export default Tag;
