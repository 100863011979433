import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../utils/constants/apiUrl';
import { STORE_CONST } from '../../utils/constants/store';
import customAxios from '../../utils/lib/customAxios';

const authentication = createAsyncThunk(
  `${STORE_CONST.AUTORIZATION_NAMESPACE}/authentication`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.LOGIN, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const wixAuthentication = createAsyncThunk(
  `${STORE_CONST.AUTORIZATION_NAMESPACE}/authentication`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.WIX_LOGIN, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const resetPassword = createAsyncThunk(
  `${STORE_CONST.AUTORIZATION_NAMESPACE}/resetPassword`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.USER_RESET_PASSWORD, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const customerRegistration = createAsyncThunk(
  `${STORE_CONST.AUTORIZATION_NAMESPACE}/customerRegistration`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.CUSTOMER_REGISTRATION,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export {
  authentication,
  resetPassword,
  customerRegistration,
  wixAuthentication,
};
