import Router from './Router';
import './App.css';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TourProvider, components, useTour } from '@reactour/tour';
import { PROJECT_CONST } from './utils/constants/projectConst';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { settingsActions } from './store/SettingsSlice';

function App() {
  console.log('process', process.env);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CustomNavigation = ({ stepsLength }) => {
    const { currentStep, setCurrentStep, isOpen, setIsOpen } = useTour();
    const [isTourOpen, setTourOpen] = useState(isOpen);

    const handleNext = () => {
      if (currentStep === 2) {
        navigate('/release-note/settings/1');
        dispatch(
          settingsActions.setCompanyProfileOpen({
            isCompanyProfileOpen: true,
          })
        );
        setTimeout(() => {
          setCurrentStep(currentStep + 1);
        }, 500);
      } else if (currentStep < stepsLength - 1) {
        setCurrentStep(currentStep + 1);
      }
    };

    const handlePrev = () => {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    };

    const finishTour = () => {
      setIsOpen(false);
      setTourOpen(false);
    };

    return (
      <div className='tour-navigation'>
        {currentStep > 0 && currentStep < 3 && (
          <button onClick={handlePrev} className='tour-prev-button'>
            Prev
          </button>
        )}
        {currentStep < stepsLength - 1 && (
          <button onClick={handleNext} className='tour-next-button'>
            Next
          </button>
        )}
        {currentStep === stepsLength - 1 && (
          <button onClick={finishTour} className='tour-next-button'>
            Finish
          </button>
        )}
      </div>
    );
  };

  const totalSteps = PROJECT_CONST.STEP.length;

  return (
    <React.Fragment>
      <TourProvider
        steps={PROJECT_CONST.STEP}
        components={{
          Navigation: () => <CustomNavigation stepsLength={totalSteps} />,
        }}
      >
        <ToastContainer />
        <Router />
      </TourProvider>
    </React.Fragment>
  );
}

export default App;
