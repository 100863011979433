import { createSlice } from '@reduxjs/toolkit';
import { STORE_CONST } from '../utils/constants/store';
import {
  deleteFeedback,
  feedBackAsRead,
  getFeedBackDetailsWithReleaseId,
  feedBackSubmit,
  forwardForAnFeedback,
  getAllFeedBacksForLevel1andLevel2,
  getEmojisForCustomer,
  getFeedBackDetails,
  getFeedbackRelease,
  getfeedbackHeader,
  reSendOtp,
  replyForAnFeedback,
  sendOtp,
  submitOtp,
  unsubscribeUser,
  unsubscribeUserFromMail,
  updateFeedBack,
  getSubscriberDetailsWithId,
  saveSubscriberPreferenceData,
  deleteFeedBack,
  deleteFeedBackById,
  getFeedbackPercentage,
} from './api/FeedbackApi';
import { PROJECT_CONST } from '../utils/constants/projectConst';
import {
  removeLocalStorage,
  toasterError,
  toasterSuccess,
} from '../utils/helper/commonHelper';
import { ERROR_CODE_CONST } from '../utils/constants/errorCode';

const FeedbackSlice = createSlice({
  name: STORE_CONST.FEEDBACK_NAMESPACE,
  initialState: {
    flag: false,
    isLoad: false,
    feedbackLibraryDetails: {
      releaseList: [],
      monthsData: [],
      tags: [],
      paginationArr: {},
      customerAttributes: {},
      isArchiveLevel2: false,
      level2Data: {},
      level1Data: {},
      contributors: [],
    },
    filterType: PROJECT_CONST.FILTER_TYPE_INITIAL,
    headerDetails: {},
    releaseLibraryFilter: {
      tagIds: [],
      monthYear: '',
      searchKey: '',
      page: 0,
    },
    feedBackDetails: {},
    level2ArchiveStatus: false,
    sessionToken: '',
    hideOtpScreen: false,
    openOtpScreen: false,
    redirect: '',
    unsubsribeDetails: {
      unsubscribeSuccess: true,
      unsubscribeMessage: '',
    },
    closeFeedBackDeletePopUp: false,
    closeUnsubscribePopUp: false,
    globalFeedBackDetails: {
      list: [],
      scorePercent: [],
      paginationArr: {},
      page: 0,
    },
    customerAttributes: {
      emojis: [],
    },
    selectedEmojis: [],
    closeReplyPopUp: false,
    closeForwardPopUp: false,
    feedbackIds: [],
    // markReadIds: [],
    clickedFeedBackId: 0,
    subscriberTagDetails: [],
    SubscriberSendAll: false,
    closeSubscriptionPreferencePopUp: false,
    otpType: 0,
    showFeedbackPage: false,
    callSecurityTypeApi: false,
    callGlobalFeedbackApi: false,
    newToken: '',
    classActive: 0,
    subscriberId: 0,
    currentMonth: '',
    feedBackMonthFilter: '',
    clearAll: false,
    initialCallForFeedBackApi: false,
    subscriberEmail: '',
    feedbackSuccess: false,
    subscribeEnable: false,
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setFlag(state, action) {
      state.flag = action.payload;
    },
    setSelectedEmojis(state, action) {
      state.selectedEmojis = action.payload.selectedEmojis;
    },
    setReplyPopUpClose(state, action) {
      state.closeReplyPopUp = action.payload.closeReplyPopUp;
    },
    setForwardPopUpClose(state, action) {
      state.closeForwardPopUp = action.payload.closeForwardPopUp;
    },
    setFilter(state, action) {
      state.releaseLibraryFilter = {
        ...state.releaseLibraryFilter,
        ...action?.payload,
      };
    },
    setFilterType(state, action) {
      state.filterType = action.payload.filterType;
    },
    setFilterForFeedBack(state, action) {
      state.globalFeedBackDetails = {
        ...state.globalFeedBackDetails,
        ...action?.payload,
      };
    },
    setFeedBackIdClick(state, action) {
      state.clickedFeedBackId = action.payload.clickedFeedBackId;
    },
    setCloseSubscriptionPreferencePopUp(state, action) {
      state.closeSubscriptionPreferencePopUp =
        action.payload.closeSubscriptionPreferencePopUp;
    },
    setSubscriberSendAll(state, action) {
      state.SubscriberSendAll = action.payload.subscriberSendAll;
    },
    setOtpType(state, action) {
      state.otpType = action.payload.otpType;
    },
    setOpenOtpScreen(state, action) {
      state.openOtpScreen = action.payload.openOtpScreen;
    },
    setShowFeedBackPage(state, action) {
      state.showFeedbackPage = action.payload.showFeedbackPage;
    },
    setCallGlobalFeedbackApi(state, action) {
      state.callGlobalFeedbackApi = action.payload.callGlobalFeedbackApi;
    },
    setFeedbackDetails(state, action) {
      state.feedBackDetails = action.payload.feedBackDetails;
    },
    setHideOtpScreen(state, action) {
      state.hideOtpScreen = action.payload.hideOtpScreen;
    },
    setClassActiveInFeedBack(state, action) {
      state.classActive = {
        ...state.classActive,
        ...action?.payload,
      };
    },
    setCurrentMonth(state, action) {
      state.currentMonth = action.payload?.monthYear;
    },
    setFeedBackMonthFilter(state, action) {
      state.feedBackMonthFilter = action.payload?.monthYear;
    },
    setClearAllTags(state, action) {
      state.clearAll = action.payload?.clearAll;
    },
    removeSubscriberTags(state, action) {
      state.subscriberTagDetails = action.payload?.removeTags;
    },
    setClearCurrentMonth(state, action) {
      state.currentMonth = action.payload?.monthYear;
    },
    setInitialFeedBackApiCall(state, action) {
      state.initialCallForFeedBackApi =
        action.payload?.initialCallForFeedBackApi;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeedbackRelease.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getFeedbackRelease.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.feedbackLibraryDetails.releaseList = payload?.response
          ?.releaseList
          ? payload?.response?.releaseList
          : [];
        state.feedbackLibraryDetails.monthsData = payload?.response?.monthsData
          ? payload?.response?.monthsData
          : [];
        state.feedbackLibraryDetails.tags = payload?.response?.tags
          ? payload?.response?.tags
          : [];
        state.feedbackLibraryDetails.paginationArr = payload?.response
          ?.paginationArr
          ? payload?.response?.paginationArr
          : {};
        state.feedbackLibraryDetails.customerAttributes =
          payload?.response?.customerAttributes;
        state.feedbackLibraryDetails.isArchiveLevel2 =
          payload?.response?.isArchiveLevel2;
        // to access the level2 data on the publice access library page
        state.feedbackLibraryDetails.level2Data = payload?.response?.level2Data;
        state.feedbackLibraryDetails.level1Data = payload?.response?.level1Data;
        state.clearAll = false;
        state.subscribeEnable = payload?.response?.subscribeEnable;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.feedbackLibraryDetails.releaseList = [];
        state.feedbackLibraryDetails.monthsData = [];
        state.feedbackLibraryDetails.tags = [];
        state.feedbackLibraryDetails.paginationArr = {};
        state.feedbackLibraryDetails.customerAttributes = [];
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        toasterError(payload?.message);
        state.feedbackLibraryDetails.releaseList = [];
        state.feedbackLibraryDetails.monthsData = [];
        state.feedbackLibraryDetails.tags = [];
        state.feedbackLibraryDetails.paginationArr = {};
        state.feedbackLibraryDetails.customerAttributes = [];
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.feedbackLibraryDetails.releaseList = [];
        state.feedbackLibraryDetails.monthsData = [];
        state.feedbackLibraryDetails.tags = [];
        state.feedbackLibraryDetails.paginationArr = {};
        state.feedbackLibraryDetails.customerAttributes = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getFeedbackRelease.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getfeedbackHeader.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getfeedbackHeader.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.headerDetails = payload?.response?.headerData;
      }
    });
    builder.addCase(getfeedbackHeader.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(getFeedBackDetails.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getFeedBackDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.feedBackDetails = {
          ...payload?.response,
          recipientId:
            payload?.response?.feedback?.releaseFeedbackId != 0
              ? payload?.response?.feedback?.recipientId
              : payload?.response?.recipientId,
          libraryUrl: payload?.response?.libraryUrl,
        };
        state.feedbackLibraryDetails.customerAttributes =
          payload?.response?.customerAttributes;
        state.subscriberTagDetails = payload?.response?.subscriber
          ?.SubscriberPreference?.tagids
          ? payload?.response?.subscriber?.SubscriberPreference?.tagids
          : [];
        state.SubscriberSendAll = payload?.response?.subscriber
          ?.SubscriberSendAll
          ? payload?.response?.subscriber?.SubscriberSendAll
          : false;
        state.subscriberId = payload?.response?.subscriber?.SubscriberID
          ? payload?.response?.subscriber?.SubscriberID
          : 0;
        state.subscriberEmail = payload?.response?.email;
        state.feedbackLibraryDetails.level2Data = payload?.response?.level2Data;
        state.feedbackLibraryDetails.level1Data = payload?.response?.level1Data;
        state.feedbackLibraryDetails.contributors =
          payload?.response?.collaborators;
        state.subscribeEnable = payload?.response?.subscribeEnable;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.feedBackDetails = {};
        state.subscriberTagDetails = [];
        state.feedbackLibraryDetails.customerAttributes = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getFeedBackDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(submitOtp.pending, (state, { payload }) => {
      state.isLoad = true;
      state.hideOtpScreen = false;
      state.classActive = 0;
    });
    builder.addCase(submitOtp.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.level2ArchiveStatus =
          payload?.response?.data?.level2ArchiveStatus;
        state.feedBackDetails = {
          ...state.feedBackDetails,
          feedback: {
            ...state.feedBackDetails.feedback,
            releaseFeedbackNote: payload?.extraParam?.feedbackNote,
            scoreId: payload?.extraParam?.scoreId?.classActive,
          },
          recipientId: payload?.response?.data?.recipientId,
          email: payload?.response?.data?.email,
          feedbackEnable: payload?.response?.data?.feedbackEnable
            ? payload?.response?.data?.feedbackEnable
            : false,
          subscriber: {
            ...state.feedBackDetails.subscriber,
          },
        };
        state.newToken = payload?.response?.data?.feedbackToken;
        state.openOtpScreen = false;
        state.sessionToken = '';
        state.hideOtpScreen = true;
        toasterSuccess(PROJECT_CONST.VERIFICATION_SUCCESS);
      } else {
        state.classActive.classActive =
          payload?.extraParam?.scoreId?.classActive;
      }
    });
    builder.addCase(submitOtp.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.hideOtpScreen = false;
      state.classActive = 0;
    });

    builder.addCase(sendOtp.pending, (state, { payload }) => {
      state.isLoad = true;
      state.openOtpScreen = false;
    });
    builder.addCase(sendOtp.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.OTP_SEND);
        state.sessionToken = payload?.response?.token;
        state.openOtpScreen = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.openOtpScreen = false;
      }
    });
    builder.addCase(sendOtp.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(reSendOtp.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(reSendOtp.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.OTP_RESEND);
      }
    });
    builder.addCase(reSendOtp.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(feedBackSubmit.pending, (state, { payload }) => {
      state.isLoad = true;
      state.redirect = '';
    });
    builder.addCase(feedBackSubmit.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.FEEDBACK_SUBMITTED_MSG);
        state.feedBackDetails = {
          ...state.feedBackDetails,
          recipientId: payload?.response?.feedback?.recipientId,
          feedback: {
            ...state.feedBackDetails.feedback,
            releaseFeedbackId: payload?.response?.feedback?.releaseFeedbackId,
            scoreId: payload?.response?.feedback?.scoreId,
            releaseFeedbackNote:
              payload?.response?.feedback?.releaseFeedbackNote,
          },
        };
        state.redirect = payload?.response?.feedbackToken;
        state.feedbackSuccess = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.feedBackDetails = {};
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        toasterError(payload?.message);
        state.feedBackDetails = {};
      }
    });
    builder.addCase(feedBackSubmit.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.redirect = '';
    });

    builder.addCase(updateFeedBack.pending, (state, { payload }) => {
      state.isLoad = true;
      state.redirect = '';
    });
    builder.addCase(updateFeedBack.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.FEEDBACK_SUBMITTED_MSG);
        state.feedBackDetails = {
          ...state.feedBackDetails,
          recipientId: payload?.response?.feedback?.recipientId,
          feedback: {
            ...state.feedBackDetails.feedback,
            releaseFeedbackId: payload?.response?.feedback?.releaseFeedbackId,
            scoreId: payload?.response?.feedback?.scoreId,
            releaseFeedbackNote:
              payload?.response?.feedback?.releaseFeedbackNote,
          },
        };
        state.redirect = payload?.response?.feedbackToken;
        state.feedbackSuccess = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.feedBackDetails = {};
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        state.feedBackDetails = {};
      }
    });
    builder.addCase(updateFeedBack.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.redirect = '';
    });

    builder.addCase(unsubscribeUser.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closeUnsubscribePopUp = false;
      state.redirect = '';
    });
    builder.addCase(unsubscribeUser.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.UN_SUBSCRIBE_MSG);
        state.feedBackDetails = {
          ...state.feedBackDetails,
          subscriber: payload?.response?.Preferences,
          unsubscribeUrl: '',
        };
        state.closeUnsubscribePopUp = true;
        state.redirect = payload?.response?.feedbackToken;
      }
    });
    builder.addCase(unsubscribeUser.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.unsubsribeDetails.unsubscribeSuccess = false;
      state.closeUnsubscribePopUp = false;
      state.redirect = '';
    });

    builder.addCase(unsubscribeUserFromMail.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(unsubscribeUserFromMail.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.unsubsribeDetails.unsubscribeMessage = payload?.response?.message;
      }
    });
    builder.addCase(unsubscribeUserFromMail.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.unsubsribeDetails.unsubscribeSuccess = false;
    });

    builder.addCase(deleteFeedback.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closeFeedBackDeletePopUp = false;
      state.redirect = '';
    });
    builder.addCase(deleteFeedback.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.FEED_BACK_DELETE);
        state.feedBackDetails = {
          ...state.feedBackDetails,
          feedback: {
            ...state.feedBackDetails.feedback,
            releaseFeedbackId: 0,
            scoreId: 0,
            releaseFeedbackNote: '',
          },
        };
        state.closeFeedBackDeletePopUp = true;
        state.redirect = payload?.response?.feedbackToken;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.feedBackDetails = {};
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        state.feedBackDetails = {};
      }
    });
    builder.addCase(deleteFeedback.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.closeFeedBackDeletePopUp = false;
      state.redirect = '';
    });
    builder.addCase(
      getFeedBackDetailsWithReleaseId.pending,
      (state, { payload }) => {
        state.isLoad = true;
        state.closeFeedBackDeletePopUp = false;
      }
    );
    builder.addCase(
      getFeedBackDetailsWithReleaseId.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.feedBackDetails = {
            ...payload?.response,
            libraryUrl: payload?.response?.libraryUrl,
          };
          state.feedbackLibraryDetails.customerAttributes =
            payload?.response?.customerAttributes;
          state.feedbackLibraryDetails.level2Data =
            payload?.response?.release?.level2Data;
          state.feedbackLibraryDetails.level1Data =
            payload?.response?.level1Data;
          state.feedbackLibraryDetails.contributors =
            payload?.response?.collaborators;
          state.subscribeEnable = payload?.response?.subscribeEnable;
        } else if (
          payload?.hasError &&
          payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
        ) {
          state.feedBackDetails = {};
          state.feedbackLibraryDetails.customerAttributes = {};
        } else if (payload?.hasError && payload?.errorCode === 1028) {
          state.feedBackDetails = {};
          state.feedbackLibraryDetails.customerAttributes = {};
        }
      }
    );
    builder.addCase(
      getFeedBackDetailsWithReleaseId.rejected,
      (state, { payload }) => {
        state.isLoad = false;
        state.closeFeedBackDeletePopUp = false;
      }
    );

    builder.addCase(
      getAllFeedBacksForLevel1andLevel2.pending,
      (state, { payload }) => {
        state.isLoad = true;
      }
    );
    builder.addCase(
      getAllFeedBacksForLevel1andLevel2.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.globalFeedBackDetails.list = payload?.response?.feedback;
          // state.globalFeedBackDetails.scorePercent =
          //   payload?.response?.scorePercent;
          state.globalFeedBackDetails.paginationArr = payload?.response
            ?.paginationArr
            ? payload?.response?.paginationArr
            : {};
        }
      }
    );
    builder.addCase(
      getAllFeedBacksForLevel1andLevel2.rejected,
      (state, { payload }) => {
        state.isLoad = false;
      }
    );

    builder.addCase(getEmojisForCustomer.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getEmojisForCustomer.fulfilled, (state, { payload }) => {
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.customerAttributes.emojis = payload?.response?.emojis;
      }
    });
    builder.addCase(getEmojisForCustomer.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(replyForAnFeedback.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closeReplyPopUp = false;
      state.callGlobalFeedbackApi = false;
    });
    builder.addCase(replyForAnFeedback.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callGlobalFeedbackApi = true;
        state.closeReplyPopUp = true;
        toasterSuccess(PROJECT_CONST.REPLY_MSG);
        state.feedbackIds = payload?.response?.feedBackIds;
        const globalFeedBackDetails = state.globalFeedBackDetails.list;
        const markReadIds = payload?.response?.markReadIds
          ? payload?.response?.markReadIds
          : [];
        markReadIds.map((id) => {
          const index = globalFeedBackDetails.findIndex(
            (x) => Number(x.releaseFeedbackId) === Number(id)
          );
          if (index > -1) {
            globalFeedBackDetails[index]['isReviewed'] = true;
          }
        });
        state.globalFeedBackDetails.list = globalFeedBackDetails;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.feedbackIds = [];
        state.globalFeedBackDetails.list = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(replyForAnFeedback.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.closeReplyPopUp = false;
      state.callGlobalFeedbackApi = false;
    });

    builder.addCase(forwardForAnFeedback.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closeForwardPopUp = false;
      state.callGlobalFeedbackApi = false;
    });
    builder.addCase(forwardForAnFeedback.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.data?.hasError && payload?.data?.errorCode === -1) {
        state.closeForwardPopUp = true;
        state.callGlobalFeedbackApi = true;
        toasterSuccess(PROJECT_CONST.FORWARD_MSG);
        state.feedbackIds = payload?.feedBackIds;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.feedbackIds = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(forwardForAnFeedback.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.closeForwardPopUp = false;
      state.callGlobalFeedbackApi = false;
    });

    builder.addCase(feedBackAsRead.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callGlobalFeedbackApi = false;
    });
    builder.addCase(feedBackAsRead.fulfilled, (state, { payload }) => {
      let index;
      let arr = [];
      state.isLoad = false;
      if (!payload?.data?.hasError && payload?.data?.errorCode === -1) {
        state.callGlobalFeedbackApi = true;
        payload?.data?.response?.feedbacks.map((list) => {
          arr.push(list.releaseFeedbackId);
          index = state.globalFeedBackDetails.list.findIndex(
            (x) => x.releaseFeedbackId === list.releaseFeedbackId
          );
          if (index > -1) {
            state.globalFeedBackDetails.list[index] = {
              ...state.globalFeedBackDetails.list[index],
              isReviewed: list?.isReviewed,
            };
          }
        });
        state.feedbackIds = arr;
        if (payload?.isRead) {
          toasterSuccess(PROJECT_CONST.MARK_AS_READ);
        } else {
          toasterSuccess(PROJECT_CONST.MARK_AS_UNREAD);
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.feedbackIds = [];
        state.globalFeedBackDetails.list = [];
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        state.feedbackIds = [];
        state.globalFeedBackDetails.list = [];
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.feedbackIds = [];
        state.globalFeedBackDetails.list = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(feedBackAsRead.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callGlobalFeedbackApi = false;
    });

    builder.addCase(
      getSubscriberDetailsWithId.pending,
      (state, { payload }) => {
        state.isLoad = true;
      }
    );
    builder.addCase(
      getSubscriberDetailsWithId.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.subscriberTagDetails =
            payload?.response?.Preferences?.SubscriberPreference?.tagids;
          state.SubscriberSendAll =
            payload?.response?.Preferences?.SubscriberSendAll;
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.subscriberTagDetails = [];
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(
      getSubscriberDetailsWithId.rejected,
      (state, { payload }) => {
        state.isLoad = false;
        state.closeForwardPopUp = false;
      }
    );

    builder.addCase(
      saveSubscriberPreferenceData.pending,
      (state, { payload }) => {
        state.isLoad = true;
        state.closeSubscriptionPreferencePopUp = false;
        state.redirect = '';
      }
    );
    builder.addCase(
      saveSubscriberPreferenceData.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.closeSubscriptionPreferencePopUp = true;
          state.openOtpScreen = false;
          state.feedBackDetails = {
            ...state.feedBackDetails,
            subscriber: payload?.response?.Preferences,
          };
          state.redirect = payload?.response?.feedbackToken;
          toasterSuccess(PROJECT_CONST.SUBSCRIBER_PREFERENCE_UPDATE);
          state.SubscriberSendAll = false;
          removeLocalStorage('selectedTags');
          removeLocalStorage('email');
          state.subscriberTagDetails = [];
          state.feedbackSuccess = false;
        } else if (
          payload?.hasError &&
          payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
        ) {
          removeLocalStorage('selectedTags');
          removeLocalStorage('email');
          state.subscriberTagDetails = [];
          state.SubscriberSendAll = false;
          state.closeSubscriptionPreferencePopUp = false;
          state.feedBackDetails = {};
        } else if (payload?.hasError && payload?.errorCode === 1028) {
          state.closeSubscriptionPreferencePopUp = false;
          state.feedBackDetails = {};
          removeLocalStorage('selectedTags');
          removeLocalStorage('email');
          state.subscriberTagDetails = [];
          state.SubscriberSendAll = false;
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          removeLocalStorage('selectedTags');
          removeLocalStorage('email');
          state.subscriberTagDetails = [];
          state.SubscriberSendAll = false;
          state.feedBackDetails = {};
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(
      saveSubscriberPreferenceData.rejected,
      (state, { payload }) => {
        state.isLoad = false;
        state.closeSubscriptionPreferencePopUp = false;
        state.redirect = '';
        removeLocalStorage('selectedTags');
        removeLocalStorage('email');
        state.subscriberTagDetails = [];
        state.SubscriberSendAll = false;
      }
    );

    builder.addCase(deleteFeedBackById.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callGlobalFeedbackApi = false;
    });
    builder.addCase(deleteFeedBackById.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callGlobalFeedbackApi = true;
        if (payload?.length > 1) {
          toasterSuccess(PROJECT_CONST.FEED_BACKS_DELETE);
        } else {
          toasterSuccess(PROJECT_CONST.FEED_BACK_DELETE);
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.feedbackIds = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteFeedBackById.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callGlobalFeedbackApi = false;
    });

    builder.addCase(getFeedbackPercentage.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getFeedbackPercentage.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.globalFeedBackDetails.scorePercent =
          payload?.response?.scorePercent;
      }
    });
    builder.addCase(getFeedbackPercentage.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
  },
});

export const feedbackActions = FeedbackSlice.actions;

export default FeedbackSlice;
